import * as React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import ContactUsPageComponent from "@components/ContactUs";

const WebsitesPage = ({ uri }) => (
  <Layout path={uri}>
    <SEO title="Contact Magyk Software" />
    <ContactUsPageComponent />
  </Layout>
);

export default WebsitesPage;
