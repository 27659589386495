import * as React from "react";
import ContactForm from "@components/ContactForm";

const ContactUsPageComponent = () => {
  return (
    <>
      <section style={{paddingTop: "145px"}}>
        <h1 style={{display: "block", marginBottom: "35px"}}>Contact Us</h1>
      </section>
      <section className="container">
        <p>Mandatory fields are marked with *</p>
        <ContactForm noTitle={true} />
      </section>
    </>
  );
};

export default ContactUsPageComponent;
